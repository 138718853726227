#camp {
  background-color: #fff;
  padding: 200px 0 240px;
  position: relative;
  background: url(../src/img/camp/ufo_light.svg)no-repeat bottom center;
  background-size: auto;
  background-position-y: 50px;
}

#camp:before {
  content: "";
  width: 210px;
  height: 160px;
  background: url(../src/img/camp/ufo.svg)no-repeat center center;
  background-size: 100%;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
}

#camp:after {
  content: "";
  width: 100%;
  height: 330px;
  background: url(../src/img/camp/campWaveBIG.png) no-repeat bottom center;
  background-size: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.campBox {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.campSwiper {
  min-width: 1220px;
  width: 1220px;
  height: 480px;
  margin: 0 auto;
  padding: 40px 0 120px;
}

.campSwiper .swiper-wrapper {
  display: flex;
  align-items: center;
  height: 480px;
}

.swiper-slide {
  /* 正常狀態下寬高 */
  height: 480px;
  width: 33.3%;

}

.campSwiper .swiper-pagination {
  bottom: 0;
  background-color: #F5FAFF;
  width: 384px;
  left: 50%;
  transform: translateX(-50%);
  padding: 28px 70px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.campSwiper .swiper-pagination-bullet {
  background: #BFD9FF;
  opacity: 1;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 8px;
  margin: 0 8px !important;
}

.campSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #004B8F;
  width: 36px;
}

.swiper-arrows {
  position: absolute;
  bottom: 0;
  width: 384px;
  height: 72px;
  display: flex;
  align-items: center;
}

.swiper-arrows button {
  border: none;
  background: none;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.swiper-arrows button.arrow-prev {
  left: 10px;
}

.swiper-arrows button.arrow-next {
  right: 10px;
}

.swiper-arrows button img {
  width: 48px;
}

.photos {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  /* 保持滾動功能 */
  -ms-overflow-style: none;
  /* IE和Edge */
  scrollbar-width: none;
  /* Firefox */
  align-items: center;
  justify-content: center;
}

.photos::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, 和 Opera */
}


.photo-container {
  padding: 0 40px;
  transition: all 0.5s ease-in-out;
  transform: translateY(40px);
}


.photo-container .photo-box {
  /* 正常狀態下寬高 */
  height: 400px;
  width: 320px;
  overflow: hidden;
  display: inline-block;
  border-radius: 24px;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.photo-container img {
  width: 100%;
  height: 100%;
  /* 圖片原始寬高 */
  transform-origin: center center;
  transition: all 0.5s ease-in-out;
  /* 平滑过渡效果 */
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.photo-container .photo-box .text {
  padding: 0 24px 40px;
  width: calc(100% - 48px);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
}

.photo-container .photo-box .text h4 {
  padding: 0;
  margin-bottom: 8px;
  line-height: 1.4;
}

.photo-container .photo-box .text p {
  opacity: 0;
  z-index: -1;
}

.photo-container .photo-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  bottom: 0;
  z-index: 1;
}

.photo-container .photo-box .text h4 {
  color: #FFF;
  font-family: 'Noto Sans TC';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
}

.photo-container .photo-box .text p {
  color: #FFF;
  font-family: 'Noto Sans TC';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  min-height: 72px;
}

.campSwiper .swiper-slide-active .photo-container {
  padding: 0;
  transform: translateY(0);
}

.campSwiper .swiper-slide-active .photo-container .photo-box {
  width: 380px;
  height: 480px;
}

.campSwiper .swiper-slide-active .photo-container .photo-box:before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 33%, rgba(0, 0, 0, 0.45) 67%, rgba(0, 0, 0, 0.75) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.campSwiper .swiper-slide-active .photo-box .text {
  bottom: 40px;
  text-align: left;
  justify-content: flex-start;
}



.photo-container.showText .photo-box .text,
.photo-container.showText .photo-box .text h4,
.photo-container.showText .photo-box .text p {
  opacity: 1;
  transition: all .5s ease-in-out;
}

.photo-container.hideText .photo-box .text {
  opacity: 0;
  transform: translateX(-50%) translateY(10px);
}

.photo-container.showText .photo-box .text {
  transform: translateX(-50%) translateY(0);
}




@media (min-width: 1920px) {
  #camp:after {
    background: url(../src/img/camp/campWaveBIG.png) no-repeat top center;
    background-size: cover;
  }
}

@media (max-width: 1440px) {
  #camp:after {
    height: 180px;
    background: url(../src/img/camp/campWaveMD.png) no-repeat bottom center;
    background-size: 100%;

  }

  #camp .container {
    padding: 0;
  }

  .swiper-slide {
    /* 正常狀態下寬高 */
    width: 33.3%;
  }
}

@media (max-width: 767px) {
  #camp {
    padding-bottom: 120px;
  }

  #camp:after {
    height: 100px;
    background: url(../src/img/camp/campWaveSM.png) no-repeat bottom center;
    background-size: 100%;
  }

  .campSwiper {
    height: 320px;
  }

  .campSwiper .swiper-wrapper {
    height: 320px;
  }

  .swiper-slide {
    width: 248px;
    height: 320px;
  }

  .campSwiper .swiper-pagination {
    width: 272px;
    padding: 16px 14px;
    border-radius: 24px;
  }

  .swiper-arrows {
    display: none;
  }

  .photo-container {
    padding: 0 4px;
    transform: translateY(0);
  }

  .photo-container .photo-box,
  .campSwiper .swiper-slide-active .photo-container .photo-box {
    width: 240px;
    height: 320px;
    border-radius: 12px;
  }

  .photo-container .photo-box .text {
    padding: 0 12px 24px;
    width: calc(100% - 24px);
  }

  .photo-container .photo-box .text h4 {
    font-size: 1rem;
  }

  .photo-container .photo-box .text p {
    font-size: 0.9rem;
    -webkit-line-clamp: 4;
    min-height: 86px;
  }

  .campSwiper .swiper-slide-active .photo-box .text {
    bottom: 0;
  }
}