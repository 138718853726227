footer {
    background: #004B8F;
    position: relative;
    padding: 30px 0 80px;
}



footer:after {
    content: "";
    width: 645px;
    height: 190px;
    background: url(../src/img/footer_smwave.png)no-repeat bottom left;
    background-size: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    max-width: 40%;
}

.footer-content {
    max-width: 1120px;
    display: flex;
    margin: 0 auto;
    box-sizing: border-box;
}

.footer_left {
    display: flex;
    width: 55%;
    position: relative;
}

.footer_left:before {
    content: "";
    width: 1px;
    height: calc(100% - 24px);
    background: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}

.footer_left .footer_logo img {
    width: 144px;
}

.footer_left .footer_text {
    padding: 45px 60px 45px 40px;
    text-align: left;
    color: #fff;
}

.footer_left .footer_text h3 {
    font-family: 'Noto Sans TC';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    padding: 5px 0;
    line-height: 1.5;
}

.footer_left .footer_text p {
    text-align: left;
    color: #fff;
    font-family: 'Noto Sans TC';
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
}

.footer_left .footer_text .logoName img {
    width: 150px;
}

.footer_left .text_end {
    text-align: left;
    color: #fff;
    padding-top: 30px;
    font-family: 'Noto Sans TC';
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
}

.footer_right {
    width: 45%;
    padding-left: 60px;
}

.footer_right img {
    width: 100%;
}

.footer_right ul {
    color: #fff;
    text-align: left;
    font-family: 'Noto Sans TC';
    font-size: 18px;
    font-style: normal;
    margin: 0;
    line-height: 1.5;
    padding: 12px 0 0;
}

.footer_right ul li {
    margin-bottom: 4px;
    position: relative;
}

.footer_right ul li.phone,
.footer_right ul li.addr {
    padding-left: 22px;
    position: relative;
    font-size: 16px;
}

.footer_right ul li span {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 4px;
}

.footer_right ul li .icon_phone {
    background: url(./img/footer_phone.svg)no-repeat center center;
    background-size: 100%;
}

.footer_right ul li .icon_addr {
    background: url(./img/footer_addr.svg)no-repeat center center;
    background-size: 100%;
}


@media (max-width: 1240px) {
    footer {
        padding: 20px 0 64px;
    }

    footer:after {
        max-width: none;
        width: 340px;
        height: 100px;
    }

    .footer-content {
        width: 100%;
        padding: 0 40px;
        max-width: none;
        align-items: center;
    }

    .footer_left {
        padding-top: 0;
    }

    .footer_left .footer_logo img {
        width: 88px;
    }

    .footer_left .footer_text {
        padding: 20px 30px 20px 25px;
    }

    .footer_left .footer_text .logoName img {
        width: 125px;
    }

    .footer_left .footer_text h3 {
        font-size: 14px;
    }

    .footer_left .footer_text p,
    .footer_left .text_end {
        font-size: 11px;
    }

    .footer_right {
        padding-left: 30px;
    }

    .footer_right ul {
        font-size: 13px;
    }

    .footer_right ul li.phone,
    .footer_right ul li.addr {
        font-size: 11px;
        padding-left: 18px;
    }

    .footer_right ul li span {
        width: 12px;
        height: 12px;
        top: 2px;
    }
}

@media (max-width: 767px) {
    footer {
        padding: 30px 0 100px;
    }

    footer:after {
        width: 280px;
    }

    .footer-content {
        flex-direction: column;
        max-width: 420px;
    }

    .footer_left:before {
        display: none;
    }

    .footer_left {
        flex-wrap: wrap;
        width: 100%;
    }

    .footer_left .footer_logo {
        flex: 0 0 70px;
    }

    .footer_left .footer_logo img {
        width: 70px;
    }

    .footer_left .footer_text {
        flex: 0 0 calc(100% - 70px);
        box-sizing: border-box;
        padding: 0;
        padding-top: 20px;
        padding-left: 24px;
        text-align: justify;
    }

    .footer_left .text_end {
        padding-top: 16px;
        padding-bottom: 8px;
        text-align: center;
        width: 100%;
    }

    .footer_right {
        width: 100%;
        padding: 0;
    }

    .footer_right img {
        width: 210px;
    }

    .footer_right ul,
    .footer_right ul li.phone,
    .footer_right ul li.addr {
        font-size: 11px;
    }

}