/* #thesis {
    background: url(./img/background/Thesis_bg.svg)no-repeat center bottom;
    background-size: cover;
    background-color: #deeffe; */
/* height: 1086px; 1126-40(padding) */
/* padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
} */

#thesis {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23f7f9fc' fill-opacity='0.88'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: repeat;
    background-position: center;
    background-size: 128px 128px;
    height: 920px;
    /*960-40(padding) */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.images-row {
    display: flex;
    /* 使用flex布局 */
    justify-content: center;
    /* 中心对齐子项 */
    align-items: center;
    /* 纵向居中 */
    flex-direction: row;
    /* 子元素沿水平方向排列 */
    width: 100%;
    /* 根据需要调整 */
}

.arrow-left {
    cursor: pointer;
    /*以下為避免選取時出現背景*/
    -moz-user-select: none;
    /* Firefox */
    -webkit-user-select: none;
    /* Chrome , Safari*/
    -ms-user-select: none;
    /* IE10 */
    -khtml-user-select: none;
    /* 古早瀏覽器 */
    user-select: none;
}

.arrow-right {
    cursor: pointer;
    /*以下為避免選取時出現背景*/
    -moz-user-select: none;
    /* Firefox */
    -webkit-user-select: none;
    /* Chrome , Safari*/
    -ms-user-select: none;
    /* IE10 */
    -khtml-user-select: none;
    /* 古早瀏覽器 */
    user-select: none;
}

.placeholder {
    width: 48px;
    height: 48px;
    background-color: transparent;
    margin: 0 20px;
    transition: background-color 0.3s ease;
    /* 添加过渡效果 */
}

.year-container {
    position: relative;
    width: 120px;
    /* 与图像尺寸相匹配 */
    height: 120px;
    /* 与图像尺寸相匹配 */
    justify-content: center;
    align-items: center;
    display: flex;
    /* 启用Flexbox布局 */
    flex-direction: row;
    /* 子元素横向排列 */
    cursor: pointer;
}

.planet-image {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.year-text {
    position: absolute;
    z-index: 1;
    color: #7EC0FC;
    text-align: center;
    /* 特規字體/論文年份使用 */
    font-family: 'Noto Sans TC';
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    /* 21.6px */

    /*以下為避免選取時出現背景*/
    -moz-user-select: none;
    /* Firefox */
    -webkit-user-select: none;
    /* Chrome , Safari*/
    -ms-user-select: none;
    /* IE10 */
    -khtml-user-select: none;
    /* 古早瀏覽器 */
    user-select: none;
}

.image-container {
    overflow-x: auto;
    display: flex;
    /* 允许水平滚动 */
    overflow-y: hidden;
    /* 隐藏垂直滚动 */
    white-space: nowrap;
    /* 防止内容换行，确保水平布局 */
    width: 1104px;
    /* 容器宽度扣箭頭 */
    height: 120px;
    /* 容器高度，确保足够容纳图片而不需要垂直滚动 */
    scrollbar-width: none;
    /* 隐藏Firefox的滚动条 */
    -ms-overflow-style: none;
    /* 隐藏IE 10+的滚动条 */
    background-color: transparent;
    /* 可选：设置背景色以增加可读性 */
    flex-direction: row;
    /* 子元素横向排列 */
}

/* 隐藏Chrome, Safari, Opera的滚动条 */
.image-container::-webkit-scrollbar {
    display: none;
}

.image {
    width: 120px;
    height: 120px;
    display: inline-block;

    /*以下為避免選取時出現背景*/
    -moz-user-select: none;
    /* Firefox */
    -webkit-user-select: none;
    /* Chrome , Safari*/
    -ms-user-select: none;
    /* IE10 */
    -khtml-user-select: none;
    /* 古早瀏覽器 */
    user-select: none;
}

.text-display-area {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    /* 确保多行内容时也能向上对齐 */
    overflow-y: auto;
    /* 允许垂直滚动 */
    height: 688px;
    /* 根据需要调整 712-24(padding) */
    width: 1168px;
    /* 根据需要调整 1216-48(padding)*/
    padding: 24px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: #fff;
    /* 可选：设置背景色以增加可读性 */
    margin-top: 20px;
    justify-content: flex-start;
    /* 确保子元素从容器的开始处排列 */
    border-radius: 32px;
    border: 0.5px #7EC0FC solid;
    transition: all ease-in-out .4s;
}

.text-display-area::-webkit-scrollbar {
    width: 6px;
    /* 滚动条宽度 */
}

/* 滚动条滑块 */
.text-display-area::-webkit-scrollbar-thumb {
    background: #CFD9E1;
    /* 滑块颜色 */
    border-radius: 4px;
    /* 滑块圆角 */
}

/* 滚动条轨道 */
.text-display-area::-webkit-scrollbar-track {
    background: #f0f0f0;
    /* 轨道颜色 */
    border-radius: 4px;
    /* 轨道圆角 */
    margin: 25px;
}

.text-display-area .detail-block {
    text-align: left;
    flex: 1 1 auto;
    /* 允许方块根据内容自动调整宽度，但不允许它们长到超过它们的内容 */
    min-width: min-content;
    /* 设置最小宽度为内容的宽度 */
    max-width: min-content;
    /* 限制最大宽度为容器的50%，减去的10px是为了考虑间隙 */
    margin-right: 16px;
    /* 给方块之间添加一些间隙 */
    margin-bottom: 24px;
    background-color: #deeffe;
    padding-left: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 24px;
    border-radius: 24px;
    /* 圆角 */
    /* 防止文本换行 */
    white-space: nowrap;
    /* 当文本超出方块宽度时，隐藏超出部分 */
    overflow: hidden;
    /* 添加省略号表示文本被截断 */
    text-overflow: ellipsis;
}

.block-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
}

.block-pacman {
    background: url(../../img/pacman.svg);
    width: 93px;
    height: 25px;
    margin-left: 10px;
}

.block-text1 {
    color: #55575A;
    font-size: 18px;
    font-family: 'Noto Sans TC';
    font-weight: 700;
    line-height: 27px;
    word-wrap: break-word
}

.block-text2 {
    color: #55575A;
    font-size: 18px;
    font-family: 'Noto Sans TC';
    font-weight: 700;
    line-height: 27px;
    word-wrap: break-word
}

@media (max-width: 768px) {
    #thesis {
        align-items: start;
        height: 920px;
        /*960-40(padding) */
    }

    .images-row {
        height: 92px;
    }

    .placeholder {
        display: none;
    }

    .arrow-left,
    .arrow-right {
        display: none;
    }

    .year-text {
        font-size: 17.5px;
        font-weight: 900;
        line-height: 21px;
    }

    .image-container {
        width: auto;
        height: 70px;
        /* 容器高度，确保足够容纳图片而不需要垂直滚动 */
    }

    .year-container {
        width: 100%;
        height: 100%;
        margin-right: -20px;
    }

    .image {
        width: 100px;
        height: 100px;
    }

    .placeholder {
        width: 87px;
        height: 62px;
        background-color: transparent;
        /* 黑色 */
        margin: 0 20px;
        /* 添加一些外边距以便于观察 */
    }

    .text-display-area {
        height: 772px;
        /* 792-20(padding) */
        min-width: 90%;
        width: auto;
        padding: 16px;
        padding-right: 0;
        padding-bottom: 0;
        margin-left: 16px;
        margin-right: 16px;
        justify-content: center;
        border-radius: 24px;
    }

    .text-display-area .detail-block {
        height: 92px;
        width: 100%;
        min-width: none;
        max-width: none;
        padding: 8px 16px;
        margin-right: 16px;
        margin-bottom: 16px;
        border-radius: 16px;
        white-space: pre-wrap;
        /* 文本换行 */
    }

    .block-top {
        margin-bottom: 4px;
    }

    .block-pacman {
        background: url(../../img/pacman.svg);
        width: 56px;
        height: 15px;
        margin-left: 10px;
        background-size: 100%;
        margin-top: 3px;
    }

    .block-text1 {
        font-size: 16px;
        line-height: 24px;
    }

    .block-text2 {
        font-size: 14px;
        line-height: 21px;
    }

}

@media (min-width: 768px) and (max-width: 959px) {
    #thesis {
        height: 760px;
        /*800-40(padding) */
    }

    .image-container {
        width: 608px;
        /* 720-112 */
    }

    .text-display-area {
        height: 556px;
        /* 576-20(padding) */
        min-width: calc(100% - 68px);
        width: auto;
        padding: 20px;
        padding-right: 0;
        padding-bottom: 0;
        margin-left: 24px;
        margin-right: 24px;
        justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .text-display-area .detail-block {
        height: 92px;
        width: auto;
        min-width: none;
        max-width: none;
        padding: 8px 16px;
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 16px;
        white-space: pre-wrap;
        /* 文本换行 */
    }

    .block-top {
        margin-bottom: 4px;
    }

    .block-pacman {
        background: url(../../img/pacman.svg);
        width: 56px;
        height: 15px;
        margin-left: 10px;
        background-size: 100%;
        margin-top: 3px;
    }

    .block-text1 {
        font-size: 16px;
        line-height: 24px;
    }

    .block-text2 {
        font-size: 14px;
        line-height: 21px;
    }
}

@media (min-width: 960px) and (max-width: 1279px) {
    #thesis {
        height: 760px;
        /*800-40(padding) */
    }

    .image-container {
        width: 752px;
        /* 864-112 */
    }

    .text-display-area {
        height: 556px;
        /* 576-20(padding) */
        min-width: calc(100% - 68px);
        width: auto;
        padding: 20px;
        padding-right: 0;
        padding-bottom: 0;
        margin-left: 24px;
        margin-right: 24px;
        justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .text-display-area .detail-block {
        height: 84px;
        /* 108-24(padding) */
        width: auto;
        min-width: none;
        max-width: none;
        margin-right: 20px;
        margin-bottom: 20px;
        white-space: pre-wrap;
        /* 文本换行 */
    }

    .block-top {
        margin-bottom: 8px;
    }

    .block-text2 {
        font-size: 16px;
        line-height: 24px;
    }
}

@media (min-width: 1280px) and (max-width: 1439px) {
    .text-display-area {
        height: 688px;
        /* 根据需要调整 712-24(padding) 設計稿論文內容有誤故另調整高度*/
        width: auto;
        margin-left: 24px;
        margin-right: 24px;
    }
}